<template>
    <!--角色详情-->
    <div>
        <div class="toolBar mBottom10" style="display: flex;align-items: center;">
            <el-button plain size='small' @click='goBack'>返回</el-button>
            <div style="flex: 1;text-align: center;">{{name}}</div>
            <el-button plain size='small' @click="refresh">刷新</el-button>
        </div>
        <el-row :gutter='10'>

            <el-col :span='6'>
                <el-card class="box-card">
                    <div slot="header">
                        <span>视图权限</span>
                    </div>
                    <div class="leftTree">
                        <el-select v-model="permissionType" @change="refresh" placeholder="请选择" class="w100"
                                   style="margin-bottom: 20px">
                            <el-option v-for="item in global.DictionaryData['pmViewType']" :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-tree ref="permissionTree"
                                 node-key="id"
                                 :data="permissionData"
                                 :props="permissionProps"
                                 :check-strictly=true
                                 show-checkbox
                                 :default-expand-all="false"
                                 @check-change="checkChange"
                                 :default-checked-keys="checkedArr"
                                 @node-click="nodeClick"
                                 default-expand-all>
                        </el-tree>
                    </div>

                </el-card>
            </el-col>
            <el-col :span='18'>
                <el-card class="box-card">
                    <div slot="header">
                        <span>操作权限</span>
                    </div>
                    <div class='leftTree'>
                        <el-checkbox style="margin-right: 30px;margin-bottom: 20px" v-for="item in operateList" @change="operateChange(item,$event)"
                                     :checked="item.isTrue" :label="item.name" :key="item.id" border>{{item.name}}
                        </el-checkbox>
                    </div>
                </el-card>
            </el-col>

        </el-row>

    </div>
</template>

<script>

    export default {
        name: 'roleDetail',
        data() {
            return {
                permissionType: 1,

                name:this.$route.query.name,

                //权限相关数据
                permissionData: null,
                permissionProps: {children: 'children', label: 'name', id: "id"},

                checkedArr: [],

                operateList: []
            }
        },
        computed : {

        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            setViewPermission() {

            },
            async checkChange(data, isChecked) {
                this.operateList = [];
                let param = {
                    isTick: 0,
                    pmIds: [data.id],
                    roleId: this.$route.query.id,
                    type: 1//视图权限为1
                };
                if (isChecked) {
                    param.isTick = 1;
                }
                let res = await this.$http.post('/sys/tickRolePermission', param);
                this.checkedArr = this.$refs.permissionTree.getCheckedKeys();
                // this.getOperateList();
            },
            async nodeClick(data){
                if(this.checkedArr.indexOf(data.id)==-1){
                    return;
                }
                let param = {
                    pmId: [data.id],
                    roleId: this.$route.query.id,
                };
                let res = await this.$http.post('/sys/rolePermissionOperateList', param);
                if(res.state) {
                    this.operateList = res.data;
                }
            },
            async getOperateList() {
                let param = {
                    pmId: this.checkedArr,
                    roleId: this.$route.query.id,
                };
                let res = await this.$http.post('/sys/rolePermissionOperateList', param);
                if(res.state) {
                    this.operateList = res.data;
                }
            },
            async getViewList(type) {
                let param = {
                    roleId: this.$route.query.id,
                    type:null
                };
                if(type){
                    param.type=type;
                }else{
                    param.type=this.permissionType
                }
                let res = await this.$http.post('/sys/rolePermissionViewList', param);
                if (res.state) {
                    this.permissionData = res.data;
                    this.getPermissionIds(this.permissionData);
                    // this.getOperateList();
                }
            },
            getPermissionIds(obj) {
                for (var a in obj) {
                    if (typeof(obj[a]) == "object") {
                        this.getPermissionIds(obj[a]); //递归遍历
                    } else {
                        if (obj['isTrue'] == true && a == 'isTrue') {
                            this.checkedArr.push(obj['id'])
                        }
                    }
                }
            },
            async operateChange(data, e) {
                let param = {
                    isTick: 0,
                    pmIds: [data.id],
                    roleId: this.$route.query.id,
                    type: 2
                };
                if (e) {
                    param.isTick = 1;
                }
                let res = await this.$http.post('/sys/tickRolePermission', param);
                console.log(this.checked)
            },
            refresh(val){
                this.getViewList(val);
            }
        },
        mounted() {
            /*this.getoptions('pmViewType');
            this.getoptions('pmOperateType');*/
            this.getViewList();
        }
    }

</script>

<style scoped>
    .leftTree {
        height: calc(100vh - 277px);
        overflow-x: auto;
        overflow-y: auto;
    }

    .handleForm {
        height: calc(100vh - 377px);
        overflow: auto;
    }
    .is-bordered{
        margin: 0;
    }
</style>